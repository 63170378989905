import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { getCookie, setCookie } from '../utils/storageHelpers';
import { REGION_GATEWAY } from '../utils/constants';
import { sendRequest } from '../utils/apiCall';
import moment from 'moment';
// import { Snowfall } from 'react-snowfall';

export default function AnnouncementWrapper({ children }) {
  const { width, height } = useWindowSize();
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [festivalImage, setFestivalImage] = useState(null);

  const fetchData = async () => {
    const url = `${REGION_GATEWAY}/coreV2/instances/festival`;

    try {
      const { response } = await sendRequest({
        url: url,
        method: 'GET',
      });

      if (response && response?.data?.data) {
        return response.data.data.instances;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Helper function to generate all dates between startDate and endDatet
  const generateImportantDates = (startDate, endDate) => {
    let importantDates = [];
    let currentDate = moment(startDate);
    const endMoment = moment(endDate);

    while (currentDate <= endMoment) {
      importantDates.push(currentDate.format('M/D'));
      currentDate.add(1, 'days');
    }

    return importantDates;
  };

  useEffect(() => {
    const checkFestivalDates = async () => {
      const today = moment().format('M/D');
      const festivals = await fetchData();

      // Filter festivals that are happening today
      const festivalDatas = festivals.filter((festival) => {
        const startDate = moment(festival.startDate);
        const endDate = moment(festival.endDate);
        const importantDates = generateImportantDates(startDate, endDate);

        // Check if today's date is within the important dates
        return importantDates.includes(today);
      });

      if (festivalDatas.length > 0) {
        if (getCookie('holiFlag') != 'shown') {
          setShowAnnouncement(true);
          setFestivalImage(festivalDatas[0].assetUrl);
        }
      }

      setTimeout(() => {
        setShowAnnouncement(false);
        setCookie('holiFlag', 'shown', 7);
      }, 5000);
    };

    checkFestivalDates();
  }, []);

  return (
    <>
      {showAnnouncement ? (
        <div>
          {/* <Snowfall color="white" snowflakeCount={300} style={{ zIndex: 30 }} /> */}
          <Transition appear show={true} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => null}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-65" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="relative w-full max-w-xl px-4 pt-4 overflow-visible text-left align-middle transition-all transform rounded-2xl">
                      <img src={festivalImage} alt="side_image" className="m-auto" />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          <div className="z-20">
            <Confetti width={width} height={height}>
              {children}
            </Confetti>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
}
